<template>
  <div class="Application">
    <div class="container">
      <b-alert class="notification" variant="success" :class="[alert_class, showDismissibleAlert ? 'show' : '']" :show="showDismissibleAlert" dismissible>{{success_msg}}</b-alert>
      
      <div class="form-wrapper">
        <div class="header-image" v-if="job&&job.custom&&job.custom.form_img">
          <img :src="$S3_bucket_url+job.custom.form_img" :alt="job.job_designation" :title="job.job_designation">
        </div>
        
        <div class="form-header">
          <img :src="$S3_bucket_url+job.company_logo_url" v-if="job&&job.company_logo_url" alt="" style="width: 200px; max-width: 100%; margin-bottom: 20px;">
          <h1>Registration Form</h1>
          <p>Please fill out the form below to complete your registration</p>
        </div>

        <v-form ref="applicationForm" lazy-validation v-model="valid">
          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-user"></i>
              <v-text-field
                type="text"
                v-model="register.name"
                :rules="[v => !!v || 'Enter name']"
                outlined
              >
                <template #label>
                  Full Name<span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-phone"></i>
              <v-text-field
                type="number"
                v-model="register.mobile"
                :rules="mobileRules"
                outlined
                @keydown.space.prevent
                hide-spin-buttons
              >
                <template #label>
                  Mobile Number<span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group" v-click-outside="hideEmailExtensions">
              <i class="fas fa-envelope"></i>
              <v-text-field
                type="text"
                v-model="register.email"
                :rules="emailRules"
                @keyup="getExtension"
                @keydown.space.prevent
                outlined
              >
                <template #label>
                  Email Address<span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
              <div class="email-dropdown-list-container" v-show="showExtensions">
                <ul class="email-dropdown-list">
                  <li
                    tabindex="-1"
                    data-dropdown-item-index="index"
                    class="email-dropdown-item"
                    v-for="(domain, index) in selectedDomains"
                    :key="index"
                    @click="setExtension(domain)"
                  >
                    <span class="email-dropdown-item-domain">@{{domain}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-map-marker-alt"></i>
              <v-autocomplete
                v-model="register.city"
                :items="locations"
                item-text="text"
                item-value="id"
                :rules="[v => !!v || 'Select current city']"
                clearable
                outlined
              >
                <template #label>
                  Current City<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-briefcase"></i>
              <v-autocomplete
                v-model="register.jobrole"
                :items="jobrole"
                item-value="value"
                item-text="text"
                :rules="[v => v.length != 0 || 'Select jobrole']"
                clearable
                outlined
                multiple
                deletable-chips
              >
                <template #label>
                  Select Preferred Job Role<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-chart-line"></i>
              <v-autocomplete
                :items="exp.options"
                item-value="value"
                item-text="text"
                :searchable="true"
                v-model="register.experience"
                :rules="[v => !!v || 'Select experience']"
                clearable
                outlined
              >
                <template #label>
                  Your Experience<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-graduation-cap"></i>
              <v-autocomplete
                v-model="register.highest_qualification"
                :searchable="true"
                :items="courses"
                item-text="text"
                item-value="id"
                :rules="[v => !!v || 'Select highest qualification']"
                @change="getSpecification"
                outlined
              >
                <template #label>
                  Highest Qualification<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-book"></i>
              <v-autocomplete
                v-model="register.specialisation"
                :items="spec"
                item-value="value"
                item-text="text"
                :rules="[v => !!v || 'Select specialisation']"
                outlined
              >
                <template #label>
                  Specialisation<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-percent"></i>
              <v-text-field
                v-model="register.highest_qualification_percentage"
                type="number"
                :rules="[v => !!v || 'Enter qualification %']"
                outlined
                hide-spin-buttons
              >
                <template #label>
                  Qualification Percentage<span class="red--text"><strong>*</strong></span>
                </template>
              </v-text-field>
            </div>
          </div>

          <div class="form-group mb-0">
            <div class="input-group">
              <i class="fas fa-calendar"></i>
              <v-autocomplete
                v-model="register.passout_year"
                :items="years"
                item-value="value"
                item-text="text"
                :rules="[v => !!v || 'Select year of passout']"
                outlined
              >
                <template #label>
                  Year of Passout<span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <!-- Custom form questions -->
          <div class="form-group mb-0" v-for="(form, key) in application_form" :key="form.qid">
            <div v-if="form.question_type == 1">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker == 1">*</span></h3>
              <div class="input-group">
                <v-text-field
                  :type="form.type || 'text'"
                  v-model="answers[key].answer"
                  class="w-100"
                  placeholder="..."
                  outlined
                />
              </div>
            </div>
            
            <div v-else-if="form.question_type == 2">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker == 1">*</span></h3>
              <div class="input-group">
                <v-textarea
                  type="text"
                  v-model="answers[key].answer"
                  cols="30"
                  placeholder="..."
                  rows="5"
                  outlined
                />
              </div>
            </div>
            
            <div v-else-if="form.question_type == 3">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker">*</span></h3>
              <div v-if="form.answers.length < 5">
                <b-form-checkbox
                  v-model="answers[key].ans_id"
                  :name="ans.answer"
                  :value="ans.ans_id"
                  v-for="(ans, index) in form.answers"
                  :key="index"
                >
                  {{ans.answer}}
                </b-form-checkbox>
              </div>
              <div v-else>
                <b-form-select v-model="answers[key].ans_id" class="mb-3">
                  <b-form-select-option :value="null" disabled>Choose branch</b-form-select-option>
                  <b-form-select-option
                    :name="ans.answer"
                    :value="ans.ans_id"
                    v-for="(ans, index) in form.answers"
                    :key="index"
                  >
                    {{ans.answer}}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            
            <div v-else-if="form.question_type == 7">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker">*</span></h3>
              <div class="record-controls">
                <button
                  type="button"
                  :class="getButtonClass(answers[key].answer)"
                  @keydown.enter.prevent
                  @click="openVideoModal($event, 'video', key, form.question)"
                >
                  <i class="fas fa-microphone"></i>
                  {{ getButtonText(answers[key].answer) }}
                </button>
                <span class="recording-status" v-if="answers[key].answer">Answer recorded</span>
              </div>
            </div>
            
            <b-form-group
              v-else-if="form.answers && form.answers.length && (form.question_type == 3 || form.question_type == 4)"
            >
              <template #label>
                {{form.question}}<span class="red--text" v-if="answers[key].deal_breaker == 1"><strong> * </strong></span>
              </template>
              <b-form-radio
                v-model="answers[key].ans_id"
                :aria-describedby="ariaDescribedby"
                :value="ans.ans_id"
                v-for="(ans, index) in form.answers"
                :key="index"
              >
                {{ans.answer}}
              </b-form-radio>
            </b-form-group>
            
            <div v-else-if="form.question_type == 5 && form.autocomplete == true">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker == 1">*</span></h3>
              <div class="input-group">
                <v-autocomplete
                  v-if="form.qp_id == 4 || form.qp_id == 23"
                  v-model="answers[key].ans_id"
                  :items="locations"
                  item-text="text"
                  item-value="id"
                  clearable
                  outlined
                  placeholder="Select an option"
                />
                <v-autocomplete
                  v-else
                  v-model="answers[key].ans_id"
                  :items="form.values"
                  item-text="text"
                  item-value="id"
                  clearable
                  outlined
                  placeholder="Select an option"
                />
              </div>
            </div>
            
            <div v-else-if="form.question_type == 5 && form.autocomplete == false">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker == 1">*</span></h3>
              <div class="input-group">
                <v-autocomplete
                  v-model="answers[key].answer"
                  :items="form.values"
                  item-text="text"
                  item-value="id"
                  :multiple="form.multiple"
                  outlined
                  :chips="form.multiple"
                  :deletable-chips="form.multiple"
                  placeholder="Select options"
                />
              </div>
            </div>
            
            <div v-else-if="form.question_type == 6">
              <h3 class="section-heading">{{form.question}}<span class="required" v-if="answers[key].deal_breaker == 1">*</span></h3>
              <div class="file-input">
                <v-file-input
                  v-model="upload_data"
                  :append-icon="$icons.file"
                  prepend-icon=""
                  accept="application/pdf"
                  @change="uploadFile(upload_data, key)"
                  show-size
                  outlined
                  placeholder="Upload File"
                />
              </div>
            </div>
          </div>

          <span class="validation-error" v-if="validation_error">{{validation_error}}</span>
          <span class="upload-error" v-if="upload_error">{{upload_error}}</span>
          
          <button type="button" class="submit-btn" @click="submitForm()" :disabled="uploading || loading">
            <span>{{ loading ? 'Submitting...' : 'Submit Application ' }}</span>
            &rarr;
            <!-- <i class="fas fa-arrow-right"></i> -->
          </button>
        </v-form>

        <VideoModal :id="current_key" :open="videoModal.open" @reset_modal="resetModal" @video_path="getVideoPath" :hideDefaultMessage="1" 
          :message="video_question" 
        />

        <div class="footer-note">
          <p>Safety alert: We are not charging&nbsp;any kind of payment for a job or hiring process. Kindly stay away from such false promises</p>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="footer-content">
        <div class="copyright">
          © {{ new Date().getFullYear() }} 
          <!-- <img src="https://s3.ap-south-1.amazonaws.com/www.hireclap.com/Assets/images/hireclap-logo-HD.png" 
               alt="Hireclap" class="footer-logo"> -->
        </div>
        <div class="made-in">
          Made with <span class="heart">&hearts;</span> in India
        </div>
        <div class="social-links">
          <a href="https://www.facebook.com/HireClap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.facebook }}</v-icon>
          </a>
          <a href="https://twitter.com/Hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.twitter }}</v-icon>
          </a>
          <a href="https://www.linkedin.com/company/hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.linkedin }}</v-icon>
          </a>
          <a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.youtube }}</v-icon>
          </a>
          <a href="https://www.instagram.com/hireclap" target="_blank">
            <v-icon style="color: #6b7280;">{{ $icons.instagram }}</v-icon>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const VideoModal = () => import(/* webpackPrefetch: true */ "@/components/VideoModal");
import Actions from '../libraries/apiActions.js'
import AwsUpload from "../../utils/s3upload"
import validation from '../libraries/validFormat'; 
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
// import {eventBus} from '../main';
import '../assets/hire.css'
export default {
  name:'PrivateJobApplicationForm',
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  components: {
    VideoModal
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    }),
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1950}, (value, index) => 1951 + index)
    }
  },
  data(){
    return{
      valid:true,
      validate: new validation(),
      action: new Actions(),
      loading:false,
      job_id:null,
      register:{
        name: null,
        email: null,
        mobile: null,
        jobrole: [],
        experience: null,
        highest_qualification: null,
        specialisation: null,
        highest_qualification_percentage: null,
        passout_year: null,
        city: null,
        src: null,
        redirect_from: null,
      },
      answers:[],
      domains: [
        "gmail.com",
        "yahoo.com",
        "yahoo.co.in",
        "yahoo.in",
        "rediffmail.com",
        "outlook.com",
        "hotmail.com",
        "ymail.com",
        "live.com",
        "mail.com",
        "rocketmail.com",
        "aol.com",
        "in.com",
        "sify.com"
      ],
      selectedDomains:[],
      showExtensions:false,
      can_register:true,
      upload_data:null,
      experience:"",
      upload_error: "",
      exp: {
        options: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 6, text: "06 Months" },
          { value: 12, text: "1 Year" },
          { value: 24, text: "2 year" },
          { value: 36, text: "3 year" },
          { value: 48, text: "4 year" },
          { value: 60, text: "5 year" },
          { value: 72, text: "6 year" },
          { value: 84, text: "6+ year" },
        ],
      },
      uploading: false,
      moreinfo:false,
      speci:false,
      spec:[],
      job:null,
      application_form:[],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      upload_progress:0,
      alert_class:null,
      showDismissibleAlert:false,
      success_msg:'',
      cities:[],
      previousUrl:null,
      videoModal:{
        open:'',
      },
      current_key: null,
      validation_error:"",
      video_question: ""
    }
  },
  beforeMount(){
    this.getYears();
    let url = this.$route.path;
    let url_split = url.split("-");
    this.job_id = url_split[url_split.length - 1];
    this.route_src = this.$route.query.src;
    this.register.redirect_from = this.$route.query.redirect_from?this.$route.query.redirect_from:null;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
  },
  mounted(){
    this.action.getFormDetails(this.job_id).then(res=>{
      if(res.status == true){
        this.job = res.job;
        this.question_form = res.questionnaire;
        if(this.question_form.length > 0){
          var self = this;
          var i =0 ;
          this.question_form.filter(function( obj ) {
            let items = [4,15,14,21,18];
            let result = items.every((item)=>{ return obj.qp_id!=item; });
            if(result == true){
              self.answers[i]={answer:null,ans_id:null,qid:obj.qid,deal_breaker:obj.deal_breaker};
              i++;
              if(obj.question_type == 1 || obj.question_type == 2){
                if(obj.qp_id == 6){
                  obj.type = 'date';
                }else if(obj.qp_id == 14|| obj.qp_id == 16|| obj.qp_id == 17|| obj.qp_id == 19 || obj.qp_id == 20 || obj.qp_id == 21 || obj.qp_id == 24){
                  obj.type = 'number';
                }else{
                  obj.type = 'text';
                }
              }else if(obj.question_type == 5){
                if(obj.qp_id == 2){
                  obj.autocomplete = false;
                  obj.multiple = true;
                  obj.values = [];
                  self.jobrole.forEach(role => {
                    obj.values.push({id:role.value,text:role.text});
                  })
                }else if(obj.qp_id == 4 || obj.qp_id == 23){
                  obj.autocomplete = true;
                  obj.values = self.locations;
                }else if(obj.qp_id == 15){
                  obj.autocomplete = true;
                  obj.values = this.courses;
                }else if(obj.qp_id == 7 || obj.qp_id == 8 || obj.qp_id == 22){
                  obj.multiple = true;
                  obj.autocomplete = false;
                  if(obj.qp_id == 7){
                    obj.values=[];
                    self.action.getLanguages().then(data => {
                      if(data.languages.length){
                        data.languages.forEach(language => {
                          obj.values.push({value:language.language,text:language.language});
                        });
                      }
                    });
                  }else if(obj.qp_id == 22){
                    obj.values=[];
                    self.action.getDesignations().then(data => {
                      if(data.designations.length){
                        data.designations.forEach(designation => {
                          obj.values.push({value:designation.designation_id,text:designation.designation_name});
                        });
                      }
                    });
                  }else{
                    obj.values = [];
                    self.action.getSkills().then(data => {
                      if(data.skills.length){
                        data.skills.forEach(skill => {
                          obj.values.push({value:skill.skill,text:skill.skill})
                        });
                      }
                    });
                  }
                }else if(obj.qp_id == 21){
                    obj.autocomplete = true;
                    obj.values = [
                                    { id : 1, text: "0 Month(Fresher)" },
                                    { id : 6, text: "06 Months" },
                                    { id : 12, text: "1 Year" },
                                    { id : 24, text: "2 year" },
                                    { id : 36, text: "3 year" },
                                    { id : 48, text: "4 year" },
                                    { id : 60, text: "5 year" },
                                    { id : 72, text: "6 year" },
                                    { id : 84, text: "6+ year" },
                                  ];
                }else{
                  console.log('here');
                }
              }
              self.application_form.push(obj);
            }
          });
        }
      }
    })
  },
  methods: {
    getVideoPath(value){
      this.answers[this.current_key].answer = value;
    },
    resetModal(){
        this.videoModal.open = '';
    },
    openVideoModal : function(event , type= '', key, question = ""){
      event.preventDefault();
        this.videoModal.open = type;
        this.current_key = key;
        this.video_question = question;
    },
    resetForm:function(){
        this.register.name = null,
        this.register.email =  null,
        this.register.password= null,
        this.register.mobile= null,
        this.register.jobrole= [],
        this.register.experience= "",
        this.register.highest_qualification= "",
        this.register.specialisation= "",
        this.register.highest_qualification_percentage= null,
        this.register.passout_year= null,
        this.register.city= "",
        this.register.sub_location= null,
        this.register.resume= null,
        this.experience = "",
        this.isSigning = false
        this.upload_error = "";
        this.validation_error = ";"
    },
    getYears(){
      var year = new Date().getFullYear();
      for(var i = year-24; i <year; i++){
        this.years.push({value:i, text:i});
      }
      for(i = year; i<=year+6; i++){
        this.years.push({value:i, text:i});
      }
    },
    getButtonText(answer) {
      return answer ? 'Answer Recorded' : 'Record Answer';
    },
    getButtonClass(answer) {
      return answer ? 'btn btn-success text-white mb-5' : 'btn record-btn mb-5';
    },
    getExtension(e){
      if(e.key == "@"){
        this.showExtensions = true;
        this.selectedDomains = this.domains
      }else{
        if(this.register.email.includes("@")){
          this.showExtensions = true;
          this.selectedDomains = [];
          var split_email = this.register.email.split("@");
          this.domains.forEach(domain => {
            if(domain.includes(split_email[1])){
              this.selectedDomains.push(domain);
              if(domain == split_email[1]){
                this.showExtensions = false;
              }
            }
          });
        }else{
          this.showExtensions = false
        }
      }
    },
    setExtension(ext){
      var split_email = this.register.email.split("@");
      this.register.email = split_email[0]+"@"+ext;
      this.showExtensions = false;
    },
    hideEmailExtensions(){
      this.showExtensions = false;
      this.selectedDomains = [];
    },
    checkMaximum(){
      if(parseInt(this.register.highest_qualification_percentage) >= 100){
        return this.register.highest_qualification_percentage.substring(0,2);
      }else{
        return this.register.highest_qualification_percentage;
      }
    },
    getSpecification: function () {
      this.spec = [];
      this.register.specialisation = "";
      this.validation = true;
      if (this.register.highest_qualification != null) {
        this.speci = true;
      }
      this.action.getSpec(this.register.highest_qualification).then((data) => {
          data.specifications.forEach((element) => {
            this.spec.push({
              value: element.bid,
              text: element.branch_name,
            });
          });
        });
    },
    async uploadFile(data,key){
      if(data){
        this.upload_error = "";
        this.uploading = true;
        const file = data;
        const awsUploader = new AwsUpload();
        try {
          var extension = data.name.split('.')[data.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var filename = 'resume/'+ current_date+"/"+timestamp + "."+extension;
          const response = await awsUploader.uploadToS3(file, filename);
          this.answers[key].answer = response.url;
          this.uploading = false;
        } catch (error) {
          this.uploading = false;
          this.upload_error = "Failed to upload file to S3:";
          console.error('Failed to upload file to S3:', error);
        }
      }
    },
    submitForm(){
      this.loading = false;
      this.valid = this.$refs.applicationForm.validate();
      let validation_errs = 0;
        this.validation_error = "";
        this.answers.forEach(ans => {
          if(ans.deal_breaker == 1 && !ans.ans_id && !ans.answer) validation_errs ++;
        })
      if(this.valid == true && validation_errs == 0 ){
          this.loading = true;
          this.action.submitPrivateJobForm(this.register,this.answers,this.job_id).then(res=>{
            this.loading = false;
            if(res.message == 'Answer submitted successfully'){
              this.showDismissibleAlert = true;
              this.success_msg = 'Job Application Submitted';
              this.alert_class = 'alert-success';
              this.$router.push({name: "private_job",params: {private_job:this.$route.params.private_job,success:"Your response has been submitted.Thanks!",status:true}});
            } else {
              this.showDismissibleAlert = false;
              this.success_msg = 'Error! Job application not submitted';
              this.alert_class = 'alert-error';
            }
          })
      } else {
        this.loading = false;
        this.validation_error = "Please fill all mandatory feilds"
      }
    }
  }
}
</script>

<style scoped>
.Application {
  background: linear-gradient(135deg, #f1f5f9 0%, #e2e8f0 100%);
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 700px !important;
  margin: 0 auto;
  padding: 40px 10px;
}

.form-wrapper {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1);
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: translateY(0);
  transition: all 0.3s ease;
  margin-bottom: 30px;
}

.form-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);
}

.header-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  background: #f8fafc;
}

.header-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #ffffff, transparent);
  z-index: 1;
}

.header-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  padding: 10px;
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.form-header {
  text-align: center;
  padding: 20px 24px 24px;
  position: relative;
  background: #ffffff;
  z-index: 2;
}

.form-header h1 {
  color: #0f172a;
  font-size: 2rem;
  margin-bottom: 8px;
  font-weight: bold;
  letter-spacing: -0.025em;
  background: linear-gradient(90deg, #2563eb, #60a5fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-header p {
  color: #475569;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 auto;
  line-height: 1.5;
}

/* Form styling */
.v-form {
  padding: 16px 24px 24px;
  max-width: 100%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 16px;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.form-group:nth-child(1) { animation-delay: 0.1s; }
.form-group:nth-child(2) { animation-delay: 0.15s; }
.form-group:nth-child(3) { animation-delay: 0.2s; }
.form-group:nth-child(4) { animation-delay: 0.25s; }
.form-group:nth-child(5) { animation-delay: 0.3s; }
.form-group:nth-child(6) { animation-delay: 0.35s; }
.form-group:nth-child(7) { animation-delay: 0.4s; }

.input-group {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.section-heading {
  font-size: 0.95rem;
  color: #475569;
  margin-bottom: 12px;
  font-weight: 500;
  line-height: 1.4;
  padding-left: 0;
}

.required {
  color: #e63946;
  margin-left: 4px;
}

/* Record button styling */
.record-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 8px;
}

.record-btn {
  background: #ffffff;
  color: #2563eb;
  border: 2px solid #2563eb;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.record-btn:hover {
  background: #2563eb;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.1);
}

.record-btn.recording {
  background: #475569;
  color: white;
  border-color: #475569;
}

.recording-status {
  font-size: 0.9rem;
  color: #475569;
}

.recording-status.recording {
  color: #475569;
}

/* File input styling */
.file-input {
  width: 100%;
}

/* Submit button styling */
.submit-btn {
  width: 100%;
  max-width: 100%;
  margin: 30px auto 0;
  padding: 14px 20px;
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  color: white;
  border: none;
  border-radius: var(--radius-md);
  font-size: 1.05rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.1);
}

.submit-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
  );
  transition: 0.5s;
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(37, 99, 235, 0.15);
}

.submit-btn:hover::before {
  left: 100%;
}

.submit-btn:disabled {
  background: var(--secondary-color);
  cursor: not-allowed;
}

/* Footer styling */
.footer-note {
  padding: 0 24px 20px;
  text-align: center;
  font-size: 12px;
  color: var(--text-secondary);
}

.footer {
  background-color: #b6b5b5;
  padding: 20px 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.footer-logo, .footer-text, .footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social {
  gap: 15px;
}

.footer-social a {
  color: white;
  transition: all 0.3s ease;
}

.footer-social a:hover {
  transform: translateY(-2px);
}

.content-center {
  text-align: center;
  margin: 0;
}

/* Email dropdown styling */
.email-dropdown-list-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  box-shadow: var(--shadow-md);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.email-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.email-dropdown-item:hover {
  background-color: #f1f5f9;
}

/* Notification styling */
.notification {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 16px 24px;
  border-radius: var(--radius-md);
  color: white;
  font-weight: 500;
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: var(--shadow-lg);
}

.notification.show {
  transform: translateY(0);
  opacity: 1;
}

.notification.alert-success {
  background: var(--success-color);
}

.notification.alert-error {
  background: var(--error-color);
}

.validation-error, .upload-error {
  color: #e63946;
  font-size: 0.9rem;
  display: block;
  margin-top: 8px;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer {
    background-color: #f3f4f6;
    padding: 0.75rem 1.5rem;
    margin-top: 3rem;
  }

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .copyright {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6b7280;
  }

  .footer-logo {
    height: 24px;
    width: auto;
  }

  .made-in {
    color: #6b7280;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .heart {
    color: #e74c3c;
  }

  
  .mdi-footer {
    color: #6b7280;
    font-size: 1.25rem;
    transition: color 0.2s ease;
  }

  .mdi-footer:hover {
    color: #2563eb;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .header-image {
      height: 250px;
    }

    .job-container {
      padding: 0 1rem;
      margin: 1rem auto;
    }

    .job-header {
      padding: 1.25rem;
    }

    .job-title-section h1 {
      font-size: 1.75rem;
    }

    .company-info {
      padding: 0.75rem;
      gap: 0.75rem;
    }

    .job-meta {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }

    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    .header-image {
      height: 200px;
    }

    .job-title-section h1 {
      font-size: 1.5rem;
    }

    .company-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn-large {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }
  }
  .social-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.social-links a {
    color: #6b7280;
    font-size: 1.25rem;
    transition: color 0.2s ease;
}

.social-links a:hover {
    color: #2563eb;
}
/* Responsive design */
@media (max-width: 768px) {
  .container {
    padding: 20px 5px;
  }
  
  .form-header {
    padding: 16px 20px;
  }
  
  .v-form {
    padding: 16px 20px 24px;
  }
  
  .form-header h1 {
    font-size: 1.7rem;
  }
  
  .submit-btn {
    padding: 14px 20px;
    font-size: 1rem;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 15px;
  }
}
</style>